import React from "react";
import { Close, Delete, PauseSharp, PlayArrow, Share } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardHeader,
  Grid,
  IconButton,
  Container,
} from "@mui/material";
import dayjs from "dayjs";
import { useMonitor, useSelectedOrganization } from "hooks";
import { Link } from "react-router-dom";

const Scheduled = () => {
  const selectedOrganization = useSelectedOrganization();
  const { monitors, deleteTimerStartEvent, switchEnableTimerStartEvent, stopNextTimerStartEvent } = useMonitor(
    selectedOrganization?.id
  );
  console.log(monitors);
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {monitors.map((timer) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              elevation={3}
              sx={{
                height: "auto",
                minHeight: 125,
                display: "flex",
                flexDirection: "column",
                borderRadius: "15px",
              }}
            >
              <CardHeader title={timer.processmodel_name} subheader={dayjs(timer.starttime).format("DD/MM/YYYY HH:mm")} />
              <CardActions>
                <IconButton onClick={() => deleteTimerStartEvent(timer)}>
                  <Delete />
                </IconButton>
                <IconButton onClick={() => switchEnableTimerStartEvent(timer)}>
                  {timer.enabled ? <PauseSharp /> : <PlayArrow />}
                </IconButton>
                <IconButton onClick={() => stopNextTimerStartEvent(timer)}>
                  <Close />
                </IconButton>
                <Link to={`/dashboard/process/edit/${timer.idprocessmodel}`}>
                  <IconButton>
                    <Share />
                  </IconButton>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Scheduled;
