import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Button,
  Menu,
  Box,
  Typography,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup as MuiFormGroup,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";
import { statisticsApi, organizationsApi } from "api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProcessesList from "./ProcessesList";
import { StartedProcess } from "./ProcessItem";
import { useForm, useTranslations } from "hooks";
import { getTranslation } from "common";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import { SelectedOrganizationType } from "model/organizationModel";
import ProcessModelFilter from "./ProccessModelFilter/ProcessModelFilter";
import { ProcessModelType } from "model/processModel";
import { SortCriterion, customSort, hasDuplicates } from "common/utilities";
import { SortState } from "hooks/useSortableList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { User } from "./UserItem";
import { Group } from "./GroupsPanel";
import { Role } from "./RolesPanel";
import { Robot } from "hooks/useRobots";

interface ProcessStartedProps {
  organization: SelectedOrganizationType;
}

const LOCAL_STORAGE_KEY = `processesFilters`;
const ProcessStarted: React.FC<ProcessStartedProps> = ({ organization }) => {
  const translations = useTranslations();
  const [processes, setProcesses] = useState<Array<StartedProcess>>([]);
  const [processModels, setProcessModels] = useState<ProcessModelType[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [showAllGroups, setShowAllGroups] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [, setRobots] = useState<Robot[]>([]);
  const [toDo, setToDo] = useState<boolean>(true);
  const [doing, setDoing] = useState<boolean>(true);
  const { values, changeHandler, setValues } = useForm(
    {
      status: "current",
      modelids: [],
      activityids: [],
      userids: [],
      groupids: [],
      roleids: [],
      search: "",
      userDataKeys: [],
    },
    []
  );

  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [sortAnchorEl, setSortAnchorEl] = useState<HTMLElement | null>(null);
  const [activities, setActivities] = useState<Array<string | null>>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [sortedProcesses, setSortedProcesses] = useState<StartedProcess[]>([]);
  const [newSort, setNewSort] = useState<SortCriterion>({});
  const [sortField, setSortField] = useState<string | null>(null);

  const [showAllActivities, setShowAllActivities] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showAllUserDataKeys, setShowAllUserDataKeys] = useState(false);
  const [showAllRoles, setShowAllRoles] = useState(false);

  // FASE 1: arrivo sulla pagina
  // FASE 2: cambio organizzazione
  // FASE 3: esco dalla pagina

  // FASE 1: inizializzo filtri
  // FASE 2: modifico filtri

  useEffect(() => {
    const savedFilters = localStorage.getItem(
      `${LOCAL_STORAGE_KEY}-${organization.id}`
    );
    if (savedFilters) {
      setValues(JSON.parse(savedFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, organization]);

  useEffect(() => {
    localStorage.setItem(
      `${LOCAL_STORAGE_KEY}-${organization.id}`,
      JSON.stringify(values)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, organization]);

  const handleChangeSort = (key: string) => {
    setNewSort((_) => {
      switch (_[key]) {
        case undefined:
        case SortState.None:
          return { [key]: SortState.Desc };
        case SortState.Asc:
          return { [key]: SortState.None };
        case SortState.Desc:
          return { [key]: SortState.Asc };
      }
    });
  };

  const sortProcesses = useCallback(
    (processes: StartedProcess[]) => {
      if (newSort.activity) {
        return [...processes].sort((a, b) => {
          const nameA = a.flownodename?.toLowerCase();
          const nameB = b.flownodename?.toLowerCase();
          if (newSort.activity === SortState.Asc) {
            return (nameA || "").localeCompare(nameB || "");
          } else {
            return (nameB || "").localeCompare(nameA || "");
          }
        });
      }
      if (newSort.model) {
        return [...processes].sort((a, b) => {
          const nameA = a.process_description.toLowerCase();
          const nameB = b.process_description.toLowerCase();
          if (newSort.model === SortState.Asc) {
            return nameA.localeCompare(nameB);
          } else {
            return nameB.localeCompare(nameA);
          }
        });
      }
      if (newSort.processmodelname) {
        return [...processes].sort((a, b) => {
          const nameA = a.processmodelname.toLowerCase();
          const nameB = b.processmodelname.toLowerCase();
          if (newSort.processmodelname === SortState.Asc) {
            return nameA.localeCompare(nameB);
          } else {
            return nameB.localeCompare(nameA);
          }
        });
      }
      if (newSort.process) {
        return [...processes].sort((a, b) => {
          const dateA = a.process_startdate
            ? new Date(a.process_startdate).getTime()
            : 0;
          const dateB = b.process_startdate
            ? new Date(b.process_startdate).getTime()
            : 0;
          return newSort.process === SortState.Asc
            ? dateA - dateB
            : dateB - dateA;
        });
      }
      if (newSort.activityStart) {
        return [...processes].sort((a, b) => {
          const dateA = a.created ? new Date(a.created).getTime() : 0;
          const dateB = b.created ? new Date(b.created).getTime() : 0;
          return newSort.activityStart === SortState.Asc
            ? dateA - dateB
            : dateB - dateA;
        });
      }

      const sortedProcesses = [...processes];
      Object.keys(newSort).forEach((key) => {
        if (newSort[key] !== SortState.None) {
          sortedProcesses.sort((a, b) => {
            const userDataA = JSON.parse(a.process_userdata || "{}");
            const userDataB = JSON.parse(b.process_userdata || "{}");
            const valueA = userDataA[key] ? userDataA[key].value : "";
            const valueB = userDataB[key] ? userDataB[key].value : "";
            return newSort[key] === SortState.Asc
              ? customSort(valueA, valueB)
              : customSort(valueB, valueA)
          });
        }
      });
      return sortedProcesses;
    },
    [newSort]
  );

  useEffect(() => {
    const filteredProcesses = processes.filter((process) => {
      const matchModels =
        values.modelids.length === 0 ||
        values.modelids.includes(process.process_idprocessmodel);
      const matchActivities =
        values.activityids.length === 0 ||
        values.activityids.includes(process.flownodename);
      const matchUserDataKeys =
        values.userDataKeys.length === 0 ||
        hasDuplicates([...values.userDataKeys, ...Object.keys(JSON.parse(process.process_userdata))])
      const matchUsers =
        values.userids.length === 0 ||
        values.userids.includes(String(process.iduser));
      const matchGroups =
        values.groupids.length === 0 ||
        values.groupids.includes(String(process.flownodename));
      const matchSearch = values.search
        ? process.processmodelname
          .toLowerCase()
          .includes(values.search.toLowerCase()) ||
        process.flownodename
          ?.toLowerCase()
          .includes(values.search.toLowerCase()) ||
        process.process_description
          .toLowerCase()
          .includes(values.search.toLowerCase()) ||
        users.some(
          (user) =>
            user.id === process.iduser &&
            (user.firstname
              .toLowerCase()
              .includes(values.search.toLowerCase()) ||
              user.lastname
                .toLowerCase()
                .includes(values.search.toLowerCase()))
        )
        : true;

      const matchStatus =
        values.status === "current" ||
        (values.status === "doing" ? process.state === 1 : process.state === 0);
      return (
        matchModels &&
        matchActivities &&
        matchUsers &&
        matchGroups &&
        matchSearch &&
        matchStatus &&
        matchUserDataKeys
      );
    });

    const sortedProcesses = sortProcesses(filteredProcesses);
    setSortedProcesses(sortedProcesses);
  }, [processes, sortProcesses, values, users, newSort]);

  // fetch dei dati iniziali (processi, modelli e utenti) all'inizializzazione del componente
  const fetchData = useCallback(async () => {
    try {
      const [{ data: processesData }, { data }] = await Promise.all([
        statisticsApi.get(`current/${organization.id}`),
        organizationsApi.get(`/${organization?.id}/processmodels/v2`),
      ]);
      setProcesses(processesData.data);
      const activeProcessModels = data.processmodels.filter((model: any) =>
        processesData.data.some(
          (process: StartedProcess) =>
            process.process_idprocessmodel === model.id
        )
      );
      setProcessModels(activeProcessModels);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  }, [organization]);

  // fetch degli utenti dell'organizzazione
  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await organizationsApi.get(`${organization.id}/users`);
      if (Array.isArray(data.users)) {
        setUsers(data.users);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  }, [organization]);

  useEffect(() => {
    // solo user che hanno modeli attivi
    const activeUserIds = new Set(
      processes
        .filter((process) => process.state === 1) // questi sono i processi in corso
        .map((process) => process.iduser) // e qua li mappiamo per utente
    );

    const activeUsers = users.filter((user) => activeUserIds.has(user.id));
    setFilteredUsers(activeUsers);
  }, [users, processes]);

  // fetch dei gruppi dell'organizzazione
  const fetchGroups = useCallback(async () => {
    try {
      const { data } = await organizationsApi.get(`${organization.id}/groups`);
      if (Array.isArray(data.groups)) {
        setGroups(data.groups);
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
      setGroups([]);
    }
  }, [organization]);

  const fetchRoles = useCallback(async () => {
    try {
      const { data } = await organizationsApi.get(`${organization.id}/roles`);
      if (Array.isArray(data.roles)) {
        setRoles(data.roles);
      } else {
        setRoles([]);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    }
  }, [organization]);

  const fetchRobots = useCallback(async () => {
    try {
      const { data } = await organizationsApi.get(`${organization.id}/robots`);
      if (Array.isArray(data.robots)) {
        setRobots(data.robots);
      } else {
        setRobots([]);
      }
    } catch (error) {
      setRobots([]);
    }
  }, [organization]);

  useEffect(() => {
    setNewSort({});
  }, [sortField]);

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchRoles();
    fetchRobots();
  }, [fetchUsers, fetchGroups, fetchRoles, fetchRobots]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClearFilters = useCallback(
    (disableLocalStorage = false) => {
      setValues({
        status: "current",
        modelids: [],
        activityids: [],
        userids: [],
        groupids: [],
        roleids: [],
        search: "",
        userDataKeys: [],
      });
      if (!disableLocalStorage) {
        localStorage.removeItem(`${LOCAL_STORAGE_KEY}-${organization.id}`);
      }
    },
    [setValues, organization]
  );

  useEffect(() => {
    return () => handleClearFilters(true);
  }, [handleClearFilters]);

  useEffect(() => {
    const value = doing && toDo ? "current" : doing ? "doing" : "todo";
    changeHandler("status", value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doing, toDo]);

  const extractUniqueActivities = (processes: StartedProcess[]) => {
    // qua le estraiamo (le attività uniché sennò mi vengono i doppioni sul filtro)
    return Array.from(
      new Set(processes.map((process) => process.flownodename))
    );
  };

  useEffect(() => {
    setActivities(extractUniqueActivities(processes));
  }, [processes]);

  const refresh = () => {
    fetchData();
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  const handleSortClick = (event: React.MouseEvent<HTMLElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleCheckboxChange = (category: string, value: string | null) => {
    changeHandler(
      category,
      values[category].includes(value)
        ? values[category].filter((v: string) => v !== value)
        : [...values[category], value]
    );
  };

  const activeFiltersCount = useMemo(() => {
    return (
      values.modelids.length +
      values.activityids.length +
      values.userids.length +
      values.groupids.length +
      (values.search ? 1 : 0) +
      values.userDataKeys.length
    );
  }, [values]);

  const activeSortsCount = useMemo(() => {
    return Object.keys(newSort).filter((key) => newSort[key]).length;
  }, [newSort]);

  const allUserDataKeys = useMemo(() => {
    const keys = new Set<string>();
    processes.forEach((process) => {
      try {
        const userData = JSON.parse(process.process_userdata);
        Object.keys(userData).forEach((key) => keys.add(key));
      } catch (e) { }
    });
    return Array.from(keys);
  }, [processes]);

  return (
    <Stack height="100%" spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Switch
                    id="switch-doing"
                    checked={doing}
                    onChange={({ target }) => {
                      if (!toDo && !target.checked) {
                        setToDo(true);
                      }
                      setDoing(target.checked);
                    }}
                  />
                }
                label={getTranslation(
                  translations,
                  "pm.label.process_status.doing"
                )}
              />
              <FormControlLabel
                control={
                  <Switch
                    id="switch-todo"
                    checked={toDo}
                    onChange={({ target }) => {
                      if (!doing && !target.checked) {
                        setDoing(true);
                      }
                      setToDo(target.checked);
                    }}
                  />
                }
                label={getTranslation(
                  translations,
                  "pm.label.process_status.todo"
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 2,
                marginRight: 2,
              }}
            >
              <Badge
                badgeContent={activeFiltersCount}
                color="primary"
                sx={{ ml: 2 }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Button
                  id="filter-menu"
                  onClick={handleFilterClick}
                  variant="outlined"
                  startIcon={<FilterListIcon />}
                >
                  {getTranslation(translations, "generic.filters.caption")}
                </Button>
              </Badge>
              <Badge
                badgeContent={activeSortsCount}
                color="primary"
                sx={{ ml: 2 }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Button
                  id="sort-menu"
                  onClick={handleSortClick}
                  variant="outlined"
                  // sx={{ ml: 2 }}
                  startIcon={<SortIcon />}
                >
                  {getTranslation(translations, "generic.sort.caption")}
                </Button>
              </Badge>
            </Box>
          </FormGroup>
        </Grid>
      </Grid>
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box component={Stack} p={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              id="search-tasks-text-monitor"
              onKeyDown={(e) => e.stopPropagation()}
              label={getTranslation(
                translations,
                "pm.organizationchart.ricerca.placeholder"
              )}
              sx={{ flex: 1 }}
              size="small"
              onChange={({ target }) => changeHandler("search", target.value)}
              value={values.search}
            />
            <IconButton color="primary" onClick={() => handleClearFilters()}>
              <FilterListOffIcon />
            </IconButton>
          </Box>
          <Box minWidth={200}>
            <Typography variant="subtitle1" gutterBottom marginTop={2}>
              {getTranslation(translations, "generic.filter.by.processmodel")}
            </Typography>
            <ProcessModelFilter
              processModelsId={values.modelids}
              onProcessModelsChange={(newModels) =>
                changeHandler("modelids", newModels)
              }
              options={processModels.map((model) => ({
                assignmentmode: 0,
                id: model.id,
                idorganization: model.idorganization,
                idprocessmodel: model.id,
                idswimlane: 0,
                idtype: model.modeltype,
                name: "",
                processmodelname: model.name,
                swimlanename: "",
              }))}
            />
          </Box>

          {/* <Grid item xs={12}>
        {!modelsLoading && processModels.length > 0 && (
          <FormControl>
            <FormLabel component="legend">
              {getTranslation(translations, "pm.modelname.label.caption")}
            </FormLabel>
            <Select
              multiple
              displayEmpty
              value={values.modelnames}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                changeHandler("modelnames", value);
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Filtra per modello di processo";
                }
                return selected.join(", ");
              }}
            >
              {processModels.map((processModel) => (
                <MenuItem key={processModel.id} value={processModel.name}>
                  <Checkbox
                    checked={values.modelnames.includes(processModel.name)}
                  />
                  <ListItemText primary={processModel.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid> */}

          <FormControl component="fieldset" sx={{ marginTop: 2 }}>
            <MuiFormGroup>
              {getTranslation(
                translations,
                "pm.associations.assigned_activity"
              )}
              {activities
                .slice(0, showAllActivities ? activities.length : 5)
                .map((activity) => (
                  <Tooltip title={activity} key={activity}>
                    <FormControlLabel
                      slotProps={{
                        typography: {
                          sx: {
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                      control={
                        <Checkbox
                          checked={values.activityids.includes(activity)}
                          onChange={() =>
                            handleCheckboxChange("activityids", activity)
                          }
                          name={activity || ""}
                        />
                      }
                      label={activity ? activity : "Activity has no name n.t."}
                    />
                  </Tooltip>
                ))}
              {activities.length > 5 && (
                <Button
                  onClick={() => setShowAllActivities(!showAllActivities)}
                  variant="text"
                  sx={{ mt: 1, mr: 22 }}
                >
                  {showAllActivities
                    ? getTranslation(translations, "generic.show.less")
                    : getTranslation(translations, "generic.show.all")}
                </Button>
              )}
            </MuiFormGroup>
          </FormControl>
          <FormControl component="fieldset" sx={{ marginTop: 2 }}>
            <FormLabel component="legend">
              {" "}
              {getTranslation(translations, "generic.user")}
            </FormLabel>
            <MuiFormGroup>
              {/* Visualizza solo i primi 5 utenti oppure tutti gli utenti se showAllUsers è true */}
              {filteredUsers
                .slice(0, showAllUsers ? filteredUsers.length : 5)
                .map((user) => (
                  <Tooltip
                    title={`${user.lastname} ${user.firstname}`}
                    key={user.id}
                  >
                    <FormControlLabel
                      slotProps={{
                        typography: {
                          sx: {
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                      control={
                        <Checkbox
                          checked={values.userids.includes(String(user.id))}
                          onChange={() =>
                            handleCheckboxChange("userids", String(user.id))
                          }
                          name={`${user.lastname} ${user.firstname}`}
                        />
                      }
                      label={`${user.lastname} ${user.firstname}`}
                    />
                  </Tooltip>
                ))}

              {/* Mostra il pulsante "Show All" o "Show Less" solo se ci sono più di 5 utenti */}
              {filteredUsers.length > 5 && (
                <Button
                  onClick={() => setShowAllUsers(!showAllUsers)} // Alterna tra mostrare tutti gli utenti e mostrarne solo 5
                  variant="text"
                  sx={{ mt: 1, mr: 2 }}
                >
                  {showAllUsers
                    ? getTranslation(translations, "generic.show.less") // true
                    : getTranslation(translations, "generic.show.all")}
                </Button>
              )}
            </MuiFormGroup>
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              <FormLabel component="legend">
                {getTranslation(translations, "generic.groups")}
              </FormLabel>
              <MuiFormGroup>
                {groups
                  .slice(0, showAllGroups ? groups.length : 5)
                  .map((group) => (
                    <Tooltip title={group.name} key={group.id}>
                      <FormControlLabel
                        slotProps={{
                          typography: {
                            sx: {
                              maxWidth: 150,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            },
                          },
                        }}
                        control={
                          <Checkbox
                            checked={values.groupids.includes(String(group.id))}
                            onChange={() =>
                              handleCheckboxChange("groupids", String(group.id))
                            }
                            name={group.name}
                          />
                        }
                        label={group.name}
                      />
                    </Tooltip>
                  ))}
                {groups.length > 5 && (
                  <Button
                    onClick={() => setShowAllGroups(!showAllGroups)}
                    variant="text"
                    sx={{ mt: 1, mr: 2 }}
                  >
                    {showAllGroups
                      ? getTranslation(translations, "generic.show.less")
                      : getTranslation(translations, "generic.show.all")}
                  </Button>
                )}
              </MuiFormGroup>
            </FormControl>
          </FormControl>
          <FormControl component="fieldset" sx={{ marginTop: 2 }}>
            <FormLabel component="legend">
              {getTranslation(translations, "placeholders.role.name.label")}
            </FormLabel>
            <MuiFormGroup>
              {roles.slice(0, showAllRoles ? roles.length : 5).map((role) => (
                <Tooltip key={role.id} title={role.name}>
                  <FormControlLabel
                    slotProps={{
                      typography: {
                        sx: {
                          maxWidth: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      },
                    }}
                    control={
                      <Checkbox
                        checked={values.roleids.includes(String(role.id))}
                        onChange={() =>
                          handleCheckboxChange("roleids", String(role.id))
                        }
                        name={role.name}
                      />
                    }
                    label={role.name}
                  />
                </Tooltip>
              ))}
              {roles.length > 5 && (
                <Button
                  onClick={() => setShowAllRoles(!showAllRoles)}
                  variant="text"
                  sx={{ mt: 1, mr: 22 }}
                >
                  {showAllRoles
                    ? getTranslation(translations, "generic.show.less")
                    : getTranslation(translations, "generic.show.all")}
                </Button>
              )}
            </MuiFormGroup>
          </FormControl>
          <FormControl component="fieldset" sx={{ marginTop: 2 }}>
            <FormLabel component="legend">
              {getTranslation(translations, "generic.userdata")}
            </FormLabel>
            <MuiFormGroup>
              {allUserDataKeys
                .slice(0, showAllUserDataKeys ? allUserDataKeys.length : 5)
                .map((key) => (
                  <Tooltip key={key} title={key}>
                    <FormControlLabel
                      slotProps={{
                        typography: {
                          sx: {
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        },
                      }}
                      control={
                        <Checkbox
                          checked={values.userDataKeys.includes(key)}
                          onChange={() =>
                            handleCheckboxChange("userDataKeys", key)
                          }
                          name={key}
                        />
                      }
                      label={key}
                    />
                  </Tooltip>
                ))}
              {allUserDataKeys.length > 5 && (
                <Button
                  onClick={() => setShowAllUserDataKeys(!showAllUserDataKeys)}
                  variant="text"
                  sx={{ mt: 1, mr: 22 }}
                >
                  {showAllUserDataKeys
                    ? getTranslation(translations, "generic.show.less")
                    : getTranslation(translations, "generic.show.all")}
                </Button>
              )}
            </MuiFormGroup>
          </FormControl>
        </Box>
      </Menu>
      <Menu
        id="sort-menu"
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleSortClose}
        anchorOrigin={{
          // ma perché non ci va
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2} component={Stack} sx={{ minWidth: 200 }}>
          <Typography variant="subtitle1" gutterBottom>
            {getTranslation(translations, "generic.sort.caption")}
          </Typography>
          <TextField
            select
            label={getTranslation(translations, "generic.sort.caption")}
            size="small"
            onChange={({ target }) => setSortField(target.value)}
            value={sortField || ""}
          >
            <MenuItem>
              {getTranslation(translations, "generic.filter.none")}
            </MenuItem>
            <MenuItem value="process">
              {getTranslation(
                translations,
                "generic.filter.data.start.process"
              )}
            </MenuItem>
            <MenuItem value="activityStart">
              {getTranslation(
                translations,
                "generic.filter.data.start.activity"
              )}
            </MenuItem>
          </TextField>
          {sortField && (
            <TextField
              select
              label={getTranslation(translations, "generic.sort.caption")}
              size="small"
              onChange={({ target }) =>
                setNewSort({ [sortField]: Number(target.value) })
              }
              value={newSort[sortField] || SortState.None}
              sx={{ mt: 2 }}
            >
              <MenuItem value={SortState.None}>
                {getTranslation(translations, "generic.filter.none")}
              </MenuItem>
              <MenuItem value={SortState.Asc}>
                {getTranslation(translations, "generic.filter.orderby.asc")}
              </MenuItem>
              <MenuItem value={SortState.Desc}>
                {getTranslation(translations, "generic.filter.orderby.desc")}
              </MenuItem>
            </TextField>
          )}
        </Box>
      </Menu>
      <ProcessesList
        handleChangeSort={handleChangeSort}
        newSort={newSort}
        refresh={refresh}
        title={getTranslation(translations, "generic.processes.monitor")}
        processes={sortedProcesses}
        filters={values}
        filteredUserDataKeys={values.userDataKeys}
      />
    </Stack>
  );
};

export default ProcessStarted;
