import React, { useState, useMemo } from "react";
import {
  FormHelperText,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
// import { processesApi } from "api";
import { formatDate, getTranslation } from "common/utilities";
import UserAvatar from "./UserAvatar";
import { DeleteForever } from "@mui/icons-material";
import { useTranslations } from "hooks";
// import { TaskDetailDialog } from "./dialogs";
import { StartedProcess } from "./ProcessItem";
import { Link } from "react-router-dom";
import { BaseTask } from "model/activitiesModel";
import BlockIcon from "@mui/icons-material/Block";

export interface TaskDetail extends BaseTask {
  designerid: string;
  user_picture: string | null;
  process_description: string;
  process_enddate: string | null;
  process_idprocessmodel: number;
  process_startdate: string | null;
  process_userdata: string;
  processduration: number;
  processmodeldeleted: boolean;
  flownodetype: number;
  idtype: number;
  iduser: number;
  previous: number;
  taskduration: number | null;
  timeindoing: number;
  timetotakeover: number;
  userfullname: string | null;
}
interface TaskItemProps {
  handleClick: (task: TaskDetail, type: "delete" | "terminate") => void;
  task: TaskDetail;
  process: StartedProcess;
  filters: Record<string, any>;
  refresh: () => void;
  userDataKeys: string[];
}

const TaskItem: React.FC<TaskItemProps> = ({
  handleClick,
  filters,
  task,
  process,
  refresh,
  userDataKeys,
}) => {
  // const selectedOrganization = useSelectedOrganization();
  const [, setSelectedTask] = useState<BaseTask | null>(null);
  // const [user] = useState<StatisticUser | null>(null);
  const translations = useTranslations();

  // useEffect(() => {
  //   (async () => {
  //     if (task.iduser) {
  //       const { data } = await usersApi.get(`${task.iduser}`);
  //       setUser(data.user);
  //     }
  //   })();
  // }, [task.iduser]);

  // const fromDoingTo_Todo = async ({
  //   idprocesstoken,
  // }: {
  //   idprocesstoken: number;
  // }) => {
  //   try {
  //     await processesApi.post(`/${idprocesstoken}/undo`);
  //   } catch {
  //   } finally {
  //   }
  // };

  const userDataMap = useMemo(() => {
    try {
      const data = JSON.parse(task.process_userdata);
      return data;
    } catch (e) {
      return {};
    }
  }, [task.process_userdata]);

  const fixHeight = {
    maxHeight: 130,
    height: 130,
    minHeight: 130,
    // boxSizing: "border-box",
    overflowY: "hidden",
  };

  return (
    <React.Fragment>
      {/* <TaskDetailDialog
        readonly
        handleBackStep={(task) => {
          fromDoingTo_Todo(task);
          refresh();
        }}
        open={selectedTask?.idprocesstoken === task.idprocesstoken}
        onClose={() => setSelectedTask(null)}
        doing={selectedTask?.state === 1}
        task={{
          ...task,
          idprocessmodel: task.process_idprocessmodel,
          idorganization: selectedOrganization?.id || 0,
          description: task.process_description,
          assignmentmode: 0,
          idswimlane: 0,
          designerid: "",
          userdata: task.process_userdata,
          jsontag: "",
          priority_name: "",
        }}
      /> */}
      <TableRow
      id={`task-row-${process.process_idprocessmodel}`}
        sx={{
          "& > td": {
            p: 2,
            lineHeight: 1,
            ...fixHeight
          },
          cursor: "pointer",
          "&:hover": { backgroundColor: (_) => _.palette.divider },
        }}
        onClick={() => setSelectedTask(task)}
      >
        <TableCell
          sx={{
            borderRight: "1px solid #ddd",
            borderLeft: "1px solid #ddd",
            py: 0,
          }}
        >
          <Stack>
            <Stack
              alignItems="center"
              spacing={0.5}
              direction="row"
              maxWidth="100%"
            >
              <span
                style={{
                  fontWeight: "bold",
                  flex: 1,
                }}
              >
                {process.process_description}
                <FormHelperText>
                  {getTranslation(translations, "pm.process.started_at")}
                  {formatDate(process.process_startdate)}
                </FormHelperText>
              </span>
              {/* <ArrowForwardSharp fontSize={"small"} />
              <Link
                onClick={(e) => void e.stopPropagation()}
                to={`/dashboard/process/edit/${process.process_idprocessmodel}`}
                style={{ fontWeight: "bold" }}
                state={{ designerid: task.designerid }}
              >
                {`${task.processmodelname}`}
              </Link> */}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell sx={{ borderRight: "1px solid #ddd" }}>
          {/* <ArrowForwardSharp fontSize={"small"} /> */}
          <Box
            sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            <Link
              onClick={(e) => void e.stopPropagation()}
              to={`/dashboard/process/edit/${process.process_idprocessmodel}`}
              style={{
                fontWeight: "bold",
              }}
              state={{ designerid: task.designerid }}
            >
              {task.processmodelname}
            </Link>
          </Box>
        </TableCell>
        {userDataKeys.map((key) => (
          <TableCell
            key={key}
            sx={{ borderRight: "1px solid #ddd" }}
          >
            <Box
              sx={{
                maxWidth: 150,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {userDataMap[key] ? `${userDataMap[key].value}` : "N/A"}
            </Box>
          </TableCell>
        ))}
        <TableCell sx={{  borderRight: "1px solid #ddd" }}>
          <Stack>
            <span>
              <Typography>{task.flownodename}</Typography>
              <FormHelperText>
                {getTranslation(translations, "pm.process.started_at")}
                {formatDate(process.process_startdate)}
              </FormHelperText>
            </span>
          </Stack>
        </TableCell>
        {filters.status === "current" && (
          <TableCell sx={{ borderRight: "1px solid #ddd" }}>
            <Typography>
              {task.state === 0
                ? getTranslation(translations, "pm.label.process_status.todo")
                : getTranslation(translations, "pm.label.process_status.doing")}
            </Typography>
          </TableCell>
        )}
        <TableCell sx={{ borderRight: "1px solid #ddd" }}>
          {task.iduser ? (
            <Stack direction="row" spacing={0.5}>
              <UserAvatar
                user={{
                  picture: task.user_picture,
                  email: "",
                  firstname: "",
                  lastname: "",
                }}
              />
              <Typography>{task.userfullname}</Typography>
            </Stack>
          ) : (
            <Typography>
              {getTranslation(translations, "monitor.no_participant")}
            </Typography>
          )}
        </TableCell>
        <TableCell sx={{ borderRight: "1px solid #ddd" }}>
          <Stack direction="row" spacing={1}>
            <Tooltip
              title={getTranslation(translations, "tooltip.terminate.process")}
            >
              <BlockIcon
              id="terminate-btn"
                onClick={(e) => handleClick(task, "terminate")}
                color="primary"
              />
            </Tooltip>
            <Tooltip
              title={getTranslation(translations, "tooltip.delete.process")}
            >
              <DeleteForever
              id="delete-4ever-btn"
                onClick={(e) => handleClick(task, "delete")}
                color="primary"
              />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default TaskItem;
